import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNOUT,
	SIGNUP,
} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
	showLoading,
} from "../actions/Auth";

import { BASE_URL } from 'redux/store/baseUrl';
import axios from 'axios';
import { message } from 'antd';
// import { userData } from 'redux/actions/UserState';

export function* signInWithEmail() {
	yield takeEvery(SIGNIN, function* ({ payload }) {
		try {
			yield put(showLoading(true))
			const res = yield call(axios.post, `${BASE_URL}/api/sign-in-admin`, payload);
			const { access_token, user } = res.data
			yield put(showLoading(false))
			if (access_token && user.id) {
				message.success("Login Successfully")
				localStorage.setItem(AUTH_TOKEN, access_token);
				yield put(authenticated(user))
			}
			// if (user.message) {
			// } else {
			// 	localStorage.setItem(AUTH_TOKEN, user.user.uid);
			// 	yield put(authenticated(user.user.uid));
			// }
		} catch (err) {
			yield put(showAuthMessage('login or password is wrong'));
			yield put(showLoading(false))

		}
	});
}

export function* signOut() {
	yield takeEvery(SIGNOUT, function* () {
		try {
			// const signOutUser = yield call(FirebaseService.signOutRequest);
			const signOutUser = {}
			if (signOutUser === undefined) {
				localStorage.removeItem(AUTH_TOKEN);
				localStorage.removeItem('is_theme_selected');
				localStorage.removeItem('selected_theme_id');
				localStorage.removeItem('is_package_selected');
				yield put(signOutSuccess(signOutUser));
			} else {
				yield put(showAuthMessage(signOutUser.message));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

// signup
export function* signUpWithFBEmail() {
	yield takeEvery(SIGNUP, function* ({ payload }) {
		// const { email, password } = payload;
		try {
			yield put(showLoading(true))
			const user = yield call(axios.post, `${BASE_URL}/users/create`, payload);
			if (user.data.message) {
				message.success(user.data.message + ' ' + `check it`)
			}
			yield put(showLoading(false))

			// if (user.message) {
			// 	yield put(showAuthMessage(user.message));
			// } else {	
			// 	localStorage.setItem(AUTH_TOKEN, user.user.uid);
			// 	yield put(signUpSuccess(user.user.uid));
			// }
		} catch (error) {
			yield put(showAuthMessage(error));
			yield put(showLoading(false))
		}
	}
	);
}

// export function* signInWithFBGoogle() {
// 	yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
// 		try {
// 			const user = yield call(FirebaseService.signInGoogleRequest);
// 			if (user.message) {
// 				yield put(showAuthMessage(user.message));
// 			} else {
// 				localStorage.setItem(AUTH_TOKEN, user.user.uid);
// 				yield put(signInWithGoogleAuthenticated(user.user.uid));
// 			}
// 		} catch (error) {
// 			yield put(showAuthMessage(error));
// 		}
// 	});
// }

// export function* signInWithFacebook() {
// 	yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
// 		try {
// 			const user = yield call(FirebaseService.signInFacebookRequest);
// 			if (user.message) {
// 				yield put(showAuthMessage(user.message));
// 			} else {
// 				localStorage.setItem(AUTH_TOKEN, user.user.uid);
// 				yield put(signInWithFacebookAuthenticated(user.user.uid));
// 			}
// 		} catch (error) {
// 			yield put(showAuthMessage(error));
// 		}
// 	});
// }

export default function* rootSaga() {
	yield all([
		fork(signInWithEmail),
		fork(signOut),
		fork(signUpWithFBEmail),
		// fork(signInWithFBGoogle),
		// fork(signInWithFacebook)
	]);
}
