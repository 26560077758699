import React, { useContext } from 'react'
import { APP_NAME } from 'configs/AppConfig';
import { LanguageContext } from 'LanguageContext';

export default function Footer() {
	const { language, setLanguage } = useContext(LanguageContext);
	console.log('language= ', language);
	return (
		<footer className="footer">
			{language=='en'?
			<span>Copyright  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">{`${APP_NAME}`}</span></span>
			:
			<span> مايكروباند كل الحقوق محفوضة © {`${new Date().getFullYear()}`} </span>
			}
		</footer>
	)
}

