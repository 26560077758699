import {
  DashboardOutlined,
  AppstoreOutlined,
  AntDesignOutlined,
  MailOutlined,
  BarsOutlined,
  PlusCircleOutlined,
  SettingOutlined,
  FormOutlined,
  FileImageOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'



// const dashBoardNavTree = [{
//   key: 'dashboards',
//   path: `${APP_PREFIX_PATH}/dashboards`,
//   title: 'sidenav.dashboard',
//   icon: DashboardOutlined,
//   breadcrumb: false,
//   submenu: [
//     {
//       key: 'dashboards-default',
//       path: `${APP_PREFIX_PATH}/dashboards/default`,
//       title: 'sidenav.dashboard',
//       icon: DashboardOutlined,
//       breadcrumb: false,
//       submenu: []
//     },
    
//   ]
// }]

const MainNavTree = [{
  key: 'main',
  path: `${APP_PREFIX_PATH}/main`,
  title: 'sidenav.main',
  icon: InfoCircleOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'main',
      path: `${APP_PREFIX_PATH}/main/main`,
      title: 'sidenav.main',
      icon: InfoCircleOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'contactUs',
      path: `${APP_PREFIX_PATH}/main/contactUs`,
      title: 'sidenav.contactUs',
      icon: InfoCircleOutlined,
      breadcrumb: false,
      submenu: []
    },
  ]
}]

const imagesNavTree = [{
  key: 'images',
  path: `${APP_PREFIX_PATH}/images`,
  title: 'sidenav.images',
  icon: FileImageOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'images',
      path: `${APP_PREFIX_PATH}/images/images`,
      title: 'sidenav.images',
      icon: FileImageOutlined,
      breadcrumb: false,
      submenu: []
    },
  ]
}]

const scNavTree = [{
  key: 'services and clients',
  path: `${APP_PREFIX_PATH}/sc`,
  title: 'sidenav.sc',
  icon: AppstoreOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'services',
      path: `${APP_PREFIX_PATH}/sc/services`,
      title: 'sidenav.sc.services',
      icon: BarsOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'clients',
      path: `${APP_PREFIX_PATH}/sc/clients`,
      title: 'sidenav.sc.clients',
      icon: BarsOutlined,
      breadcrumb: false,
      submenu: []
    },
    
  ]
}]

const idNavTree = [
  {
    key: 'input and data',
    path: `${APP_PREFIX_PATH}/id`,
    title: 'sidenav.id',
    icon: AntDesignOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'who we are',
        path: `${APP_PREFIX_PATH}/id/wwa`,
        title: 'sidenav.wwa',
        icon: FormOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'privacy',
        path: `${APP_PREFIX_PATH}/id/privacy`,
        title: 'sidenav.privacy',
        icon: FormOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'legacy',
        path: `${APP_PREFIX_PATH}/id/legacy`,
        title: 'sidenav.legacy',
        icon: FormOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'rights',
        path: `${APP_PREFIX_PATH}/id/rights`,
        title: 'sidenav.rights',
        icon: FormOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'qlook',
        path: `${APP_PREFIX_PATH}/id/qlook`,
        title: 'sidenav.qlook',
        icon: FormOutlined,
        breadcrumb: true,
        submenu: []
      },

    ]
  }
]

const settingsNavTree = [
  {
    key: 'settings',
    path: `${APP_PREFIX_PATH}/settings`,
    title: 'sidenav.settings',
    icon: SettingOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'shortcuts',
        path: `${APP_PREFIX_PATH}/settings/shortcuts`,
        title: 'sidenav.shortcuts',
        icon: SettingOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'social-networks',
        path: `${APP_PREFIX_PATH}/settings/sn`,
        title: 'sidenav.sn',
        icon: SettingOutlined,
        breadcrumb: true,
        submenu: []
      },
      // {
      //   key: 'main-office',
      //   path: `${APP_PREFIX_PATH}/settings/office`,
      //   title: 'sidenav.office',
      //   icon: SettingOutlined,
      //   breadcrumb: true,
      //   submenu: []
      // },
    ]
  }
]

// const emailsNavTree = [
//   {
//     key: 'emails',
//     path: `${APP_PREFIX_PATH}/emails`,
//     title: 'sidenav.emails',
//     icon: PlusCircleOutlined,
//     breadcrumb: true,
//     submenu: [
//       {
//         key: 'emails-config',
//         path: `${APP_PREFIX_PATH}/emails/create`,
//         title: 'sidenav.create.emails',
//         icon: MailOutlined,
//         breadcrumb: true,
//         submenu: []
//       },
//     ]
//   }
// ]

// const usersNavTree = [
//   {
//     key: 'users',
//     path: `${APP_PREFIX_PATH}/users`,
//     title: 'sidenav.users',
//     icon: PlusCircleOutlined,
//     breadcrumb: true,
//     submenu: [
//       {
//         key: 'users-list',
//         path: `${APP_PREFIX_PATH}/users/list`,
//         title: 'sidenav.users.list',
//         icon: MailOutlined,
//         breadcrumb: true,
//         submenu: []
//       },
//     ]
//   }
// ]
const navigationConfig = [
  // ...dashBoardNavTree,
  ...MainNavTree,
  ...imagesNavTree,
  ...scNavTree,
  ...idNavTree,
  ...settingsNavTree,
  // ...emailsNavTree,
  // ...usersNavTree,
]

export default navigationConfig;
