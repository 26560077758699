import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, PACKAGES_PREFIX_PATH, THEMES_PREFIX_PATH } from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';

function RouteInterceptor({ children, isAllowed, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAllowed ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH + "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}



export const Views = (props) => {
  const { locale, token, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);
  console.log(direction, '//////')

  const isPackagesSelected = localStorage.getItem('is_package_selected') == 'true' ? true : false
  const isThemeSelected = localStorage.getItem('is_theme_selected') == 'true' ? true : false

  return (
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
          <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
            <Switch>
              <Route exact path="/">
                <Redirect to={token !== null ? APP_PREFIX_PATH + "/main/main" : AUTH_PREFIX_PATH + "/login"} />
              </Route>

              <Route path={AUTH_PREFIX_PATH + "/login"}>
                <AuthLayout direction={direction} />
              </Route>

              <RouteInterceptor isAllowed={token !== null}>
                <AppLayout direction={direction} location={location} />
              </RouteInterceptor>
            </Switch>
          </ConfigProvider>
        </IntlProvider>
  )
}


const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, direction, token }
};

export default withRouter(connect(mapStateToProps)(Views));