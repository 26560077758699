import antdFrFR from 'antd/es/locale/ar_EG';
import frMsg from "../locales/ar_EG.json";

const FrLang = {
  antd: antdFrFR,
  locale: 'ar-EG',
  messages: {
    ...frMsg
  },
};
export default FrLang;
