import React, { useContext } from "react";
import { CheckOutlined, GlobalOutlined, DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from "antd";
import lang from "assets/data/language.data.json";
import { connect } from "react-redux";
import { onLocaleChange, onDirectionChange } from 'redux/actions/Theme'
import { DIR_RTL, DIR_LTR } from 'constants/ThemeConstant';
import { LanguageContext } from "LanguageContext";

function getLanguageDetail(locale) {
	const data = lang.filter(elm => (elm.langId === locale))
	return data[0]
}

const SelectedLanguage = ({ locale }) => {
	const language = getLanguageDetail(locale)
	const { langName, icon } = language
	return (
		<div className="d-flex align-items-center">
			<img style={{ maxWidth: '20px' }} src={`/img/flags/${icon}.svg`} alt={langName} />
			<span className="font-weight-semibold ml-2">{langName} <DownOutlined className="font-size-xs" /></span>
		</div>
	)
}

export const NavLanguage = ({ locale, direction, configDisplay, onLocaleChange }) => {
	const { language, setLanguage } = useContext(LanguageContext);

	const languageOption = (
		<Menu>
			{
				lang.map((elm, i) => {
					return (
						<Menu.Item
							key={elm.langName}
							className={locale === elm.langId ? 'ant-dropdown-menu-item-active' : ''}
							onClick={(e) => {
								onLocaleChange(elm.langId)
								console.log('elm.langId= ', elm.langId)
								if (elm.langId == 'en') {
									const sections = [...document.getElementsByTagName('section')];
									sections.forEach((section) => { section.className = 'ant-layout ant-layout-ltr' });
									document.dir = DIR_LTR;
									document.paddingLeft = 250;
									document.paddingRight = 0;
									document.body.className = 'dir-ltr'
									setLanguage('en')
									const lists = [...document.getElementsByTagName('ul')]
									lists.forEach((ul) => { 
										ul.dir = DIR_LTR 
										ul.className="ant-menu ant-menu-root ant-menu-inline ant-menu-light ant-menu-ltr"
									});

								} else {
									const sections = [...document.getElementsByTagName('section')];
									sections.forEach((section) => { section.className = 'ant-layout ant-layout-rtl' });
									document.dir = DIR_RTL;
									document.paddingLeft = 0;
									document.paddingRight = 250;
									document.body.className = 'dir-rtl'
									const lists = [...document.getElementsByTagName('ul')]
									lists.forEach((ul) => { 
										ul.dir = DIR_RTL
										ul.className = "ant-menu ant-menu-root ant-menu-inline ant-menu-light ant-menu-rtl"
									 });
									 setLanguage('ar')
								}
							}}
						>
							<span className="d-flex justify-content-between align-items-center">
								<div>
									<img style={{ maxWidth: '20px' }} src={`/img/flags/${elm.icon}.svg`} alt={elm.langName} />
									<span className="font-weight-normal ml-2">{elm.langName}</span>
								</div>
								{locale === elm.langId ? <CheckOutlined className="text-success" /> : null}
							</span>
						</Menu.Item>
					)
				})
			}
		</Menu>
	)
	return (
		<Dropdown placement="bottomRight" overlay={languageOption} trigger={["click"]}>
			{
				configDisplay ?
					(
						<a href="#/" className="text-gray" onClick={e => e.preventDefault()}>
							<SelectedLanguage locale={locale} />
						</a>
					)
					:
					(
						<Menu mode="horizontal">
							<Menu.Item key="language">
								<a href="#/" onClick={e => e.preventDefault()}>
									<GlobalOutlined className="nav-icon mr-0" />
								</a>
							</Menu.Item>
						</Menu>
					)
			}
		</Dropdown>
	)
}

const mapStateToProps = ({ theme }) => {
	const { locale } = theme;
	return { locale }
};

export default connect(mapStateToProps, { onLocaleChange })(NavLanguage);
