import React, { createContext, useState } from 'react';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('AR');
  const [direction, setDirection] = useState('rtl')

  return (
    <LanguageContext.Provider value={{ language, setLanguage, direction, setDirection }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const LanguageConsumer = LanguageContext.Consumer;