import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        {/* <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} /> */}
        <Route path={`${APP_PREFIX_PATH}/main`} component={lazy(() => import(`./main`))} />
        <Route path={`${APP_PREFIX_PATH}/sc`} component={lazy(() => import(`./servicesClients`))} />
        <Route path={`${APP_PREFIX_PATH}/id`} component={lazy(() => import(`./inputData`))} />
        <Route path={`${APP_PREFIX_PATH}/settings`} component={lazy(() => import(`./settings`))} />
        <Route path={`${APP_PREFIX_PATH}/emails`} component={lazy(() => import(`./emails`))} />
        <Route path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./users`))} />
        <Route path={`${APP_PREFIX_PATH}/images`} component={lazy(() => import(`./images`))} />
        
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/main`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);
